.footer {
    padding: 16px 0;
    background-color: #000000C5;
    font-family: Helvetica, Arial, sans-serif;

    [class*="container-"] {
      padding: 0;
    }

    a {
      color: $white !important;

      transition: color 0.2s ease-in-out, border-color 0.2s ease-in-out;

      &.btn-icon.btn-neutral.btn-round.btn-simple {
        font-size: 0.875rem !important;
      }

      &:hover {
        color: $primary !important;
        border-color: $primary !important;
      }
    }

    .nav {
      display: block;
      float: left;
      margin-bottom: 0;
      list-style: none;
    }

    .nav-item {
      display: block;
    }

    .nav-link {
      padding: 10px 0;
      text-transform: uppercase;
      text-decoration: none;

      & h4 {
        display: flex;
        flex-direction: row;
        font-weight: 300;
        font-size: 24px;
        gap: 8px;
        margin-bottom: 0;
        color: inherit;
        justify-content: center;
        align-items: center;
      }

      &:hover{
        text-decoration: none;
      }
    }

    .title {
      text-align: left !important;
    }

    .credits {
      display: flex;
      flex-direction: row;
      gap: 8px;
      margin: 0 auto;

      ul {
        display: flex;
        flex-direction: row;
        gap: 8px;
        list-style: none;
        margin-bottom: 0;
        padding-left: 0;
      }
    }

    .copyright{
        line-height: 1.8;
        color: $white;
    }

    .row {
      justify-content: space-between;

      & > div {
        display: flex;
        justify-content: center;

        & > * {
          display: flex;
          flex-direction: row;
          gap: 16px;
          justify-content: center;
          align-items: center;
        }
      }
    }

    #logo {
      display: flex;
      font-family: 'Audiowide', cursive;
      font-size: 2.5rem;
      font-weight: 600;
      color: $primary;
      text-transform: uppercase;

      @media screen and (max-width: 400px){
        flex-direction: column;

        img {
          margin: 0 auto;
        }
      }
    }

    &:after{
        display: table;
        clear: both;
        content: " ";
    }
}


@media screen and (max-width: 991px){
  .footer {
    padding-left: 0px;

    .copyright {
      text-align: right;
      margin-right: 15px;
    }
  }
}

@media screen and (min-width: 992px){
  .footer {
    .copyright {
      float: right;
      padding-right: 30px;
    }
  }
}

@media screen and (max-width: 768px){
  .footer {
    nav {
      display: block;
      margin-bottom: 5px;
      float: none;
    }
  }
}

@media screen and (max-width: 576px){
  .footer {
    text-align: center;
    .copyright {
      text-align: center;
    }

    .nav{
      float: none;
      padding-left: 0;
    }
  }
}
