// style for the landing page
.index-page {
    .page-header {
        height: 100vh;

        .container{
            > .content-center {
                &.brand {
                  .h1-seo,
                  h3{
                    color: $white;
                    font-weight: 600;
                    text-transform: capitalize;
                    span{
                      font-weight: $font-weight-bold;
                    }
                }
              }
            }
        }

        .category-absolute {
          position: absolute;
          top: 100vh;
          margin-top: -60px;
          padding: 0 15px;
          width: 100%;
          color: rgba(255,255,255,.5);
        }
    }

    .squares{
      animation: move-left-right 1s infinite;
      @include linear-gradient($primary-states, $primary);
      position: absolute;
      transition: 0.5s ease-out;
      overflow: hidden;
      border-radius: 20%;
      &.square1{
        animation: move-left-right 4s infinite;
        height: 300px;
        width: 300px;
        opacity: 0.5;
        left: 3%;
        top: -21%;
      }

      &.square2{
        animation: move-left-right 6s infinite;
        height: 400px;
        width: 400px;
        opacity: 0.4;
        right: -5%;
        top: -12%;
      }

      &.square3{
        animation: move-left-right 5s infinite;
        height: 200px;
        width: 200px;
        opacity: 0.1;
        left: -5%;
        bottom: 0%;
      }

      &.square4{
        animation: move-left-right 10s infinite;
        height: 100px;
        width: 100px;
        opacity: 0.9;
        right: 27%;
        top: 70%;
      }

      &.square5{
        animation: move-left-right 6s infinite;
        height: 250px;
        width: 250px;
        opacity: 0.1;
        left: 32%;
        bottom: 29%;
      }

      &.square6{
        animation: move-left-right 9s infinite;
        left: 10%;
        top: 35%;
        height: 80px;
        width: 80px;
        opacity: 0.8;
      }

      &.square7{
        animation: move-left-right 3s infinite;
        width: 300px;
        height: 300px;
        right: -5%;
        bottom: 0%;
        opacity: 0.1;
      }
    }
}
.landing-page, .jobs-page {
  overflow-x: hidden;

  .page-header {

    &::after {
      background: radial-gradient(ellipse at top right, #292D61 23%, #171941 65%);
      // content: "";
      position: absolute;
      height: 100vh;
      width: 100%;
      opacity: .5;
    }
    .path {
      max-width: 65%;
      right: 0;
      top: 30px;
    }

    .path2 {
      position: absolute;
      opacity: 0.02;
      max-width: 41%;
      right: -60px;
      top: -50px;
    }

    .squares {
      top: 50%;
      right: 18%;
      max-height: 200px;
    }

    .wave {
      top: 45%;
      right: 30%;
      max-height: 90px;
    }

    .circle {
      top: 20%;
      right: 36%;
      max-height: 75px;
    }

    .triangle {
      top: 35%;
      left: 5%;
      max-height: 200px;
    }
  }

  .section:first-of-type {
    .path {
      left: -110px;
      max-width: 60%;
      top: -50px;
    }
  }
  .section:nth-of-type(2) {

    .path {
      left: auto;
      right: -250px;
      max-width: 70%;
      top: 0;
    }
    .path2 {
      position: absolute;
      opacity: 0.02;
      right: auto;
      left: 420px;
      max-width: 10%;
      top: 420px;
    }

    .path3 {
      position: absolute;
      opacity: 0.02;
      left: auto;
      right: 500px;
      max-width: 10%;
      top: -90px;
    }
  }
  .section:nth-of-type(3) {
    padding-top: 200px;

    .path {
      right: auto;
      left: 50px;
      max-width: 45%;
      top: 60px;
    }
  }
  .section:nth-of-type(4) {
    .path {
      max-width: 60%;
      right: 3%;
      top: 15%;
    }
    .path2 {
      position: absolute;
      opacity: 0.02;
      left: 25%;
      right: auto;
      max-width: 10%;
      top: 100px;
    }
  }
  .section:nth-of-type(5) {
    .path {
      max-width: 50%;
      left: 5%;
      top: 3%;
    }
  }

  .header{
      height: 100vh;
      position: relative;

      .container{
          padding-top: 26vh;
          color: $white;
          z-index: 2;
          position: relative;
      }

      .share{
          margin-top: 150px;
      }
      h1{
          font-weight: 600;
      }
      .title{
          color: $white;
      }
  }

    .section-team{
        .team .team-player img{
            max-width: 100px;
        }

        .team-player{
            margin-bottom: 15px;
        }
    }

    .section-contact-us{
        .title{
            margin-bottom: 15px;
        }

        .description{
            margin-bottom: 30px;
        }

        .input-group,
        .send-button,
        .textarea-container{
            padding: 0 40px;
        }

        .textarea-container{
            margin: 40px 0;
        }

        a.btn{
            margin-top: 35px;
        }
    }

    .section-safe {
      .icon {
        i {
          font-size: 21px;
        }
      }
    }
}

// style for the profile page
.profile-page{

  overflow-x: hidden;

    .page-header{
      min-height: 550px;

      .container {
        padding-top: 30vh;
      }

      .path {
        max-width: 65%;
        right: 0;
        top: 100px;
      }
    }

    .info {
      padding: 40px 0 0;

      .description {
        text-align: left;
        padding-left: 20px;
      }
    }

    .profile-title {
      position: absolute;
      top: 110px;
      left: 60px;
    }

    .profile-description {
      margin-top: -30px;
      max-width: 450px;
      font-size: $font-size-base;
      line-height: 26px;
    }

    .profile-container {
        color: $white;
    }
    .photo-container {
      max-width: 400px;
      overflow: hidden;
      -webkit-box-shadow: -15px 20px 60px 0px rgba(0,0,0,0.30);
      -moz-box-shadow: -15px 20px 60px 0px rgba(0,0,0,0.30);
      box-shadow: -15px 20px 60px 0px rgba(0,0,0,0.30);
    }

    .title{
      text-align: center;
      margin-top: 30px;
    }

    .description,
    .category{
        text-align: center;
    }

    h5.description {
        max-width: 700px;
        margin: 20px auto 75px;
    }

    .nav-align-center {
        margin-top: 30px;
    }

    .content {
      .social-description {
        display: inline-block;
        max-width: 150px;
        width: 145px;
        text-align: center;
        margin: 15px 0 0px;

        h2 {
          margin-bottom: 15px;
        }
      }
    }

    .collections {
        img {
            margin-bottom: 30px;
        }
    }

    .gallery {
        margin-top: 45px;
        padding-bottom: 50px;
    }

    .card-coin {
      .list-group{
        margin: 0 !important;
        text-align: left !important;
        color: $opacity-5;
      }
    }
    .card {
      .card-header {
        .profile-title {
          top: 125px;
        }
      }
    }
}

.section-full-page{

    &:after,
    &:before{
        display: block;
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 2;
    }

    &:before{
        background-color: rgba(0,0,0,.5);
    }

    &[data-image]:after{
        opacity: .5;
    }

    > .content,
    > .footer{
        position: relative;
        z-index: 4;
    }

    > .content{
        min-height: calc(100vh - 80px);
    }

    .full-page-background{
        position: absolute;
        z-index: 1;
        height: 100%;
        width: 100%;
        display: block;
        top: 0;
        left: 0;
        background-size: cover;
        background-position: center center;

    }

    .footer nav > ul a:not(.btn),
    .footer,
    .footer .copyright a{
        color: $white;
    }

}

.jobs-page{

    background-color: $background-black;

    .link{
        font-size: 10px;
        color: $white;
        text-decoration: none;
    }



    .register-bg{
      height: 700px;
      background: linear-gradient(to bottom, $transparent-bg 0%, $card-black-background 100%);
      width: 700px;
      position: relative;
      right: -600px;
      top: -750px;
      border-radius: 20%;
      transform: rotate(-15deg);
    }

    .square{
      position: absolute;
      transition: 0.5s ease-out;
      border-radius: 2px;
      background-size: 100%;
      border-radius: 20%;
    }

    .square-1{
      height: 300px;
      width: 300px;
      background-image: url($asset-base-path + "/img/square1.png");
      top: 175px;
      right: 150px;
      opacity: 0.8;
    }

    .square-2{
      height: 120px;
      width: 120px;
      background-image: url($asset-base-path + "/img/square2.png");
      opacity: 0.9;
      top: 310px;
      right: 390px;
    }

    .square-3{
      width: 200px;
      height: 200px;
      background-image: url($asset-base-path + "/img/square3.png");
      opacity: 0.3;
      top: -50px;
      right: 400px;
    }

    .square-4{
      height: 200px;
      width: 200px;
      background-image: url($asset-base-path + "/img/square4.png");
      right: -50px;
      top: 525px;
    }

    .square-5{
      height: 150px;
      width: 150px;
      background-image: url($asset-base-path + "/img/square5.png");
      opacity: 0.5;
      top: 500px;
      right: 470px;
    }

    .square-6{
      height: 70px;
      width: 70px;
      background-image: url($asset-base-path + "/img/square5.png");
      opacity: 0.9;
      top: 105px;
      right: 20px;
    }

    .square-7{
      height: 200px;
      width: 200px;
      background-image: url($asset-base-path + "/img/square5.png");
      left: -100px;
      top: 100px;
      opacity: .4;
    }

    .square-8{
      height: 100px;
      width: 100px;
      background-image: url($asset-base-path + "/img/square1.png");
      left: 300px;
      top: 400px;
      opacity: .4;
    }
}

.index-page,
.landing-page,
.profile-page,
.jobs-page{
  background-image: url($asset-base-path + "/img/dots.png");
  background-size: contain;
}

.landing-page, .jobs-page {
  background-color: $white;
}

.landing-page .fallback {
  color: $tertiary;
}

.landing-page #aboutUs {
  isolation: isolate;
  display: flex;
  flex-direction: row;
  gap: 32px;
  align-items: center;

  @media (max-width: 991px) {
    flex-direction: column;

    img {
      margin: 0 auto;
    }
  }

  p, h1, h4 {
    font-family: Helvetica, Arial, sans-serif;
  }

  p, h1 {
    color: $primary;
  }

  h4 {
    font-size: 1.2rem;
    color: $black;
  }

  h1 {
    font-size: 2.5rem;
    font-weight: 800;
  }

  & > div > p {
    font-weight: 600;
  }

  #aboutSections {
    display: grid;
    gap: 2rem;
    grid-template-columns: 1fr 1fr;

    p {
      color: $tertiary;
      font-family: Helvetica, Arial, sans-serif;
      font-size: 1rem;

      &.title {
        font-size: 1.25rem;
        font-weight: 400;
        color: $primary;
      }
    }

    @media (max-width: 991px) {
      grid-template-columns: 1fr;
    }
  }
}

.landing-page #cta {
  margin-top: 32px;
  margin-bottom: 64px;
  justify-content: center;
  align-items: center;
  gap: 32px;

  h3, span {
    color: $tertiary;
    font-family: Helvetica, Arial, sans-serif;
  }

  h3 {
    color: $primary;
    font-size: 2.5rem;
    font-weight: 600;
  }

  span {
    font-size: 1rem;
  }

  .btn-primary {
    max-width: 400px;
    background-color: $primary;
    background-image: none;
    font-family: 'Montserrat', Arial, sans-serif;
    font-size: 1rem;
    padding: 16px 32px;
    color: $white;
    border: 1px solid transparent;

    transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  
    &:hover, &:focus {
      color: $primary;
      background-color: $white !important;
      background-image: none !important;
      border-color: $primary;
      box-shadow: none;
    }
  }
}

.landing-page #iconCards {
  display: grid;
  gap: 32px;
  grid-template-columns: 1fr 1fr 1fr;

  @media (max-width: 991px) {
    grid-template-columns: 1fr;
  }

  .card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: none;
    box-shadow: none;

    h3, p {
      font-family: Helvetica, Arial, sans-serif;
    }

    h3 {
      font-size: 1.2rem;
      color: $black;
    }

    p {
      font-size: 1rem;
      color: $secondary;
    }

    .card-header {
      margin-bottom: 8px;

      .backdrop {
        width: 64px;
        height: 64px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      i {
        text-align: center;
        position: relative;
        top: 24px;
        color: $white;
        font-size: 32px;
      }
    }
  }
}

.landing-page #header {
  isolation: isolate;
  margin-top: 185px;
  

  h1 {
    color: $primary;
    font-weight: 800;
    font-size: 3rem;
  }

  h1, p {
    font-family: Helvetica, Arial, sans-serif;
  }

  .text-black {
    color: $tertiary;
  }

  .button-container {
    display: flex;
    flex-direction: row;
    gap: 8px;

    @media screen and (max-width: 768px) {
      justify-content: center;
    }

    a {
      will-change: color, background-color;
      color: $tertiary;
      background-color: transparent;
      border-radius: 50%;
      font-size: 16pt;
      border: 1px solid $tertiary;
      padding: 4px 8px;

      transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;

      &:hover, &:focus {
        color: $primary;
        background-color: $tertiary;
        border-color: $primary;
      }
    }
  }
}

@include media-breakpoint-down(md){
  .section:nth-of-type(2) {
    .path {
      left: 10% !important;
    }
    .path2 {
      left: 20% !important;
    }
  }

  .info:first-of-type {
      padding-top: 10% !important;
  }
}

@include media-breakpoint-down(sm){
  .card-login img{
    width: 70%;
  }

  .section:nth-of-type(2) {
    .path,
    .path2 {
      left: 0 !important;
    }
  }
  .section-coins {
    margin-top: 0 !important;
  }
}

@include media-breakpoint-down(xs){
  .card-login {
    margin-top: 100px;
      img{
      width: 350px;
    }
  }

  .profile-page .page-header{
    max-height: unset;
  }

  .landing-page{
    .page-header{
      h1{
        margin-top: 100px;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .info:first-of-type {
    padding-top: 60%;
  }
}

// Jobs page
.jobs-page .wrapper {

  & .page-header {
    min-height: calc(100vh - 350px);

    & > .container {
      margin-top: 250px;

      .input-group-text {
        border-color: $tertiary;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right-color: transparent;

        &:has(+ input:focus), &:has(+ select:focus) {
          border-color: $primary;
          border-right-color: transparent;
        }
      }

      label:has(+ .input-group > input[required]),
      label:has(+ .input-group > select[required]),
      label:has(+ input[required]),
      label:has(+ select[required])
      {
        &::after {
          content: "*";
          color: maroon;
        }
      }

      label, input, select, textarea, span, button.form-control {
        color: $tertiary;
        font-family: Helvetica, Arial, sans-serif;
        font-size: 15pt;
        height: auto;

        &[disabled] {
          color: $tertiary;
          background-color: $quternary;
        }

        &[type="submit"], &[type="button"] {
          font-family: Montserrat, Arial, sans-serif;
          font-size: 16pt;
          height: auto;

          &:not([disabled]):hover {
            background-color: $primary;
            border-color: $primary;
            color: $white;
          }
        }
      }

      #form {
        margin: 150px auto;

        .form-group:has(> .file-label) {

          .file-label {
            display: flex;
            flex-direction: row;
            gap: 10px;
            align-items: center;

            i {
              cursor: pointer;
              will-change: color;
              transition: color 0.2s ease-in-out;

              &:hover {
                color: $primary;
              }
            }
          }
        }

        .btn-group {
          display: grid;
          gap: 10px;
          // As many columns as possible, with a minimum width of 100px
          grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));

          .btn {
            height: 80px;
            font-size: 15pt;
            font-family: Montserrat, Arial, sans-serif;
          }
        }

        .form-check-input {
          position: unset;
          margin: 0;
          margin-right: 10px;
        }

        p {
          color: maroon;
          font-family: Helvetica, Arial, sans-serif;
          font-size: 15pt;
        }
      }
    }

    #stepHolder {
      display: grid;
      // By default, the grid will have 6 columns
      // and 1 row, but on smaller screens, it will
      // try to fit as many columns as possible
      // with a minimum width of 135px
      grid-template-columns: repeat(auto-fit, minmax(135px, 1fr));
      gap: 20px;

      // The columns should all be centered, both horizontally
      // and vertically
      justify-items: center;
      align-items: center;
      justify-content: center;

      .step {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &-num, &-name {
          font-family: Helvetica, Arial, sans-serif;
          color: $tertiary;
        }

        &:not(:last-child, .disabled, :has(+ .disabled)) {
          .step-num {
            &::after {
              position: absolute;
              content: "";
              display: block;
              width: 100px;
              background-color: $primary;
              height: 3px;
              transform: translate(36px, -15px);
            }
          }
        }

        &-name {
          font-size: 12px;
        }

        &.disabled {
          pointer-events: none;
          
          .step-num, .step-name {
            color: $quternary;
          }
        }

        &.active {
          .step-num, .step-name {
            font-weight: 800;
          }
        }

        &:not(.disabled),&.active {
          .step-num {
            padding: 0 10px;
            border-radius: 50%;
            background-color: $primary;
          }
        }

        &:hover:not(.disabled) {
          .step-num {
            background-color: lighten($primary, 5%);
          }
        }
      }
    }

    @media screen and (max-width: 1199px) and (min-width: 992px) {
      .step:nth-child(5n+5) {
        .step-num::after {
          display: none !important;
        }
      }
    }

    @media screen and (max-width: 991px) and (min-width: 768px) {
      .step:nth-child(4n+4) {
        .step-num::after {
          display: none !important;
        }
      }
    }

    @media screen and (max-width: 767px) and (min-width: 505px) {
      .step:nth-child(3n+3) {
        .step-num::after {
          display: none !important;
        }
      }
    }

    @media screen and (max-width: 504px) and (min-width: 351px) {
      .step:nth-child(2n+2) {
        .step-num::after {
          display: none !important;
        }
      }
    }

    @media screen and (max-width: 350px) {
      .step:nth-child(1n+1) {
        .step-num::after {
          display: none !important;
        }
      }
    }

    h1 {
      font-family: Helvetica, Arial, sans-serif;
      font-size: 3rem;
      font-weight: 700;
      color: $tertiary;
    }
  }
}

// Form style updates
.full-name {
  display: flex;
  justify-content: space-between;
  gap: 2rem;

  .first-name, .last-name {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}

.form-divider {
  margin-top: 2.5rem;
}

.form-divider-inner {
  margin-top: 1rem;
}

.input-container {
  position: relative;
  width: 20%;
}

.input-container input {
  padding-right: 30px;
  width: 100%;
}

.currency-symbol {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

.black-text-form {
  color: $tertiary !important;
}
